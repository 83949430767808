import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCosmetologyCommissionsByUser } from '../../../../appRedux/actions';
import { stringDate, stringTime } from '../../../../util/formatTime';
import { Table } from 'antd';

export const CosmetologyCommissionModalBody = ({ id }) => {
  const dispatch = useDispatch();
  const { cosmetologyCommissionsByUser } = useSelector(
    (state) => state.cashSales
  );
  const { filtersData } = useSelector((state) => state.generalStates);

  useEffect(() => {
    dispatch(getCosmetologyCommissionsByUser(id, { params: filtersData }));
  }, [dispatch, id, filtersData]);

  const columns = [
    {
      title: 'Orden',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Comisionista',
      dataIndex: 'seller',
      key: 'seller',
      render: (seller) =>
        `${seller?.name} ${seller?.lastName} ${seller?.motherLastName || ''}`,
    },
    {
      title: 'Sucursal',
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => branch?.name,
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (dateTime) => {
        const [date] = dateTime.split('T');
        return stringDate(date);
      },
    },
    {
      title: 'Hora',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (dateTime) => {
        const [, time] = dateTime.split('T');
        return stringTime(time);
      },
    },
    {
      title: 'Cliente',
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) =>
        `${customer?.name} ${customer?.lastName} ${
          customer?.motherLastName || ''
        }`,
    },
    {
      title: 'Venta',
      dataIndex: 'total',
      key: 'total',
      render: (total) => `$${total}`,
    },
  ];

  return (
    <Table
      dataSource={cosmetologyCommissionsByUser}
      columns={columns}
      pagination={{
        defaultPageSize: 5,
        pageSizeOptions: [5, 10, 15],
      }}
    />
  );
};
