import { useSelector } from 'react-redux';
import { getDailyCashClosingCash } from '../../../../appRedux/actions';
import {
  dailyCashClosingConst,
  filters,
  searchFilters,
  searchInputInitialValues,
} from '../../../../constants/DailyCashClosing';
import { formatPrice } from '../../../../util/formatPrice';
import { stringDate } from '../../../../util/formatTime';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { useState } from 'react';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { Button } from 'antd';
import { DailyCashClosingCashModal } from '../DailyCashClosingCashModal';

export const DailyCashClosingCashTable = () => {
  const {
    privileges: { branch: privileges = [] },
  } = useSelector((state) => state.userAuth);
  const [modalData, setModalData] = useState();
  const { modal } = useCustomModal();

  const columns = [
    {
      title: 'Sucursal',
      sorter: true,
      dataIndex: 'branch',
      key: 'branch',
      path: 'branch.name',
      render: (branch, row) => (
        <Button
          type="link"
          onClick={() => {
            modal();
            setModalData({ branchId: row?.branchId, date: row?.date });
          }}
        >
          {branch}
        </Button>
      ),
    },
    {
      title: 'Efectivo',
      sorter: true,
      dataIndex: 'cashTotal',
      key: 'cashTotal',
      render: (cashTotal) => `${formatPrice(cashTotal || '0')}`,
    },
    {
      title: 'Devoluciones',
      sorter: true,
      dataIndex: 'refundCash',
      key: 'refundCash',
      render: (refundCash) => `${formatPrice(refundCash || 0)}`,
    },
    {
      title: 'Total',
      sorter: true,
      dataIndex: 'total',
      key: 'total',
      render: (cashTotalDollars) => `${formatPrice(cashTotalDollars || '0')}`,
    },
    {
      title: 'Fecha',
      sorter: true,
      dataIndex: 'date',
      key: 'date',
      render: (date) => stringDate(date),
      path: 'date',
      formatter: (date) => stringDate(date),
    },
    {
      title: 'Usuario',
      sorter: true,
      dataIndex: 'userId',
      key: 'userId',
      render: (userId, { userName, userLastName }) =>
        userId ? `${userName} ${userLastName}` : 'Automático',
    },
  ];

  return (
    <>
      <TableFilters
        privileges={privileges}
        searchFilters={searchFilters}
        filters={filters}
        searchInputInitialValues={searchInputInitialValues}
      />
      <ListTable
        columns={columns}
        name={'corteSucursalEfectivo'}
        getDataFunction={getDailyCashClosingCash}
      />
      <ModalView title={dailyCashClosingConst.MODAL_TITLE} width={1200}>
        <DailyCashClosingCashModal
          branchId={modalData?.branchId}
          date={modalData?.date}
        />
      </ModalView>
    </>
  );
};
