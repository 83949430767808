import { Space, Table } from 'antd';
import './styles.css';
import React, { useEffect, useRef, useState } from 'react';
import { isArray } from 'lodash';
import { useReactToPrint } from 'react-to-print';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { clearFiltersData } from '../../../appRedux/actions/General';

export const ListTable = ({
  data = [],
  columns,
  size = 'middle',
  standOut = false,
  rowClass = null,
  component = null,
  privileges = null,
  getDataFunction,
  params = {},
}) => {
  const dispatch = useDispatch();
  const { filtersData } = useSelector((state) => state?.generalStates);
  const { dataDeleted } = useSelector((state) => state?.common);
  const { maxMin, stockAdjustment } = useSelector((state) => state?.stock);
  // const [display, setDisplay] = useState('none');
  const [dataSource, setDataSource] = useState([]);
  const [totalRows, setTotalRows] = useState(1);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (setDisplay === 'block') setTimeout(() => setDisplay('none'), 3000);
  // }, [setDisplay]);

  useEffect(() => {
    dispatch(clearFiltersData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData, maxMin, stockAdjustment, dataDeleted]);

  const getData = (page = 1, take = 15, order, field) => {
    setLoading(true);
    dispatch(
      getDataFunction({
        page,
        take,
        limit: 1,
        order,
        field,
        active: 1,
        status: 1,
        withDeleted: 'false',
        ...params,
        ...filtersData,
      })
    ).then((res) => {
      if (res) {
        setDataSource(res?.data?.items);
        setTotalRows(res?.data?.meta?.itemCount);
        setLoading(false);
      }
    });
  };

  // columns = columns.map((column) => {
  //   if (column.title !== 'Opciones') {
  //     return {
  //       ...column,
  //       sorter: (a, b) => {
  //         const aValue =
  //           column.path === undefined
  //             ? a[column.dataIndex] || ''
  //             : leaf(a, column) || '';

  //         const bValue =
  //           column.path === undefined
  //             ? b[column.dataIndex] || ''
  //             : leaf(b, column) || '';
  //         return aValue.toString().localeCompare(bValue.toString());
  //       },
  //     };
  //   }
  //   return column;
  // });

  const leaf = (obj, column) => {
    let value = '';
    const { path, formatter } = column;

    if (path === 'default') {
      return formatter(obj);
    }
    if (isArray(path)) {
      let values = [];
      for (const p of path) {
        const leafValue =
          p.split('.').reduce((value, el) => value[el] || '', obj) || '';
        values.push(`${leafValue}`);
        value = formatter ? formatter(values) : values.join(' ');
      }
    } else {
      const leafValue =
        path.split('.').reduce((value, el) => value[el] || '', obj) || '';
      value = formatter ? formatter(leafValue) : leafValue;
    }
    return value;
  };
  const exportData = [];

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      const component = componentRef.current;
      return component;
    },
  });

  data = data?.map((element, index) => {
    const result = {};
    for (const column of columns) {
      if (column.title !== 'Opciones') {
        result[column.title] =
          column.path === undefined
            ? element[column.dataIndex]
            : leaf(element, column);
      }
    }
    exportData.push(result);
    return {
      ...element,
      key: index,
    };
  });
  return (
    <>
      <Space align="end" style={{ marginBottom: '15px', display: 'flex' }}>
        {(privileges == null || privileges.includes('reports')) && (
          <>
            {/* <Button type="primary" htmlType="submit" className="btn-form">
              <CSVLink filename={`${name}.csv`} data={exportData}>
                <FileExcelOutlined /> {buttonLabel.exportCSV}
              </CSVLink>
            </Button>
            <Button
              onClick={() => {
                setDisplay('block');
                setTimeout(() => handlePrint(), 2000);
              }}
              type="primary"
              htmlType="submit"
              className="btn-form"
            >
              <FilePdfOutlined /> {buttonLabel.exportPDF}
            </Button> */}
          </>
        )}
        {component && component}
      </Space>
      {/*<div*/}
      {/*  ref={componentRef}*/}
      {/*  style={{*/}
      {/*    display,*/}
      {/*    padding: 50,*/}
      {/*    zIndex: -1000,*/}
      {/*    position: 'absolute',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <h1>{name}</h1>*/}
      {/*  <Table*/}
      {/*    size={size}*/}
      {/*    className="gx-table-responsive export-table"*/}
      {/*    columns={columns.filter((column) => column.title !== 'Opciones')}*/}
      {/*    dataSource={[...data]}*/}
      {/*    pagination={false}*/}
      {/*    bordered*/}
      {/*    rowClassName={*/}
      {/*      standOut*/}
      {/*        ? ({ quantity, max, min }) =>*/}
      {/*            (max > 0 && quantity > max) || (min > 0 && quantity < min)*/}
      {/*              ? 'row-danger'*/}
      {/*              : ''*/}
      {/*        : ''*/}
      {/*    }*/}
      {/*  />*/}
      {/*</div>*/}
      <Space
        align="end"
        style={{ float: 'right', marginBottom: '10px', marginTop: '0' }}
      >
        Total de registros:{totalRows}
      </Space>

      <Table
        loading={loading}
        size={size}
        columns={columns}
        dataSource={[...dataSource]}
        pagination={{
          defaultPageSize: 15,
          pageSizeOptions: [5, 10, 15, 30, 45, 60],
          total: totalRows,
          // onChange: (page, take) => {
          //   getData(page, take);
          // },
        }}
        onChange={(page, _, sort) => {
          getData(page.current, page.pageSize, sort.order, sort.field);
        }}
        bordered
        rowClassName={standOut && rowClass ? rowClass : null}
      />
    </>
  );
};
