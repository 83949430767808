import { LockOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import {
  messages,
  minLengthValidation,
  placeHolders,
  regex,
} from '../../../constants';

export const PasswordValidatorForm = () => {
  return (
    <>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: messages.required('Contraseña'),
          },
          {
            min: 8,
            message: minLengthValidation('Contraseña', 8),
          },
          {
            pattern: regex.strongPassword,
            message: messages.validation.password,
          },
        ]}
      >
        <Input.Password
          size="large"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder={placeHolders.newPassword}
        />
      </Form.Item>
      <Form.Item
        name="passwordConfirm"
        rules={[
          {
            required: true,
            message: messages.required('Confirmar Contraseña'),
          },
          {
            min: 8,
            message: minLengthValidation('Confirmar Contraseña', 8),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Las contraseñas no coinciden!'));
            },
          }),
          {
            pattern: regex.strongPassword,
            message: messages.validation.password,
          },
        ]}
      >
        <Input.Password
          size="large"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder={placeHolders.confimPassword}
        />
      </Form.Item>
    </>
  );
};
