import moment from 'moment';
import { startCase } from 'lodash';
import { Select } from 'antd';
import { timeFormat12, timeFormat, timeFormat24 } from '../constants';
moment.locale('es-mx');

export const setDecimalToTime = (time) => {
  if (time?.includes('.')) {
    return time?.replace('.', ':');
  }
  return time;
};

export const setTimeToDecimal = (time) => {
  if (time?.includes(':')) {
    const [hour, minutes] = time.split(':');
    return `${hour}.${minutes}`;
  }
  return time;
};

export const stringDate = (date) => {
  if (!date) return '-';
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  if (typeof date === 'string') {
    date = new moment(date);
  }
  return new Date(date).toLocaleString('es-MX', {
    ...options,
    timeZone: 'America/Tijuana',
  });
};

export const daysInMonth = (year, month) => {
  const days = new Date(year, month, 0);
  return days.getDate();
};

export const setTimeFormat = (value) => {
  const timeFormat = 'HH:mm';

  if (typeof value !== 'string') {
    value = moment(value)?.format(timeFormat);
  }
  const time = value?.replace('.', ':');
  return moment(time, timeFormat);
};

export const addDaysToDate = (date, days = 0) => {
  const newDate = date ? new Date(date) : new Date();
  newDate.setDate(newDate.getDate() + 1 + days);
  return newDate;
};

export const getAge = (birthday) => {
  const date = new Date(birthday);
  const ageDifMs = Date.now() - date.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getInitCalendar = (value) => {
  const start = 0;
  const end = 12;
  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();
  const months = [];
  const year = value.year();
  const month = value.month();
  const options = [];

  for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(startCase(localeData.monthsShort(current)));
  }

  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i} className="month-item">
        {months[i]}
      </Select.Option>
    );
  }

  for (let i = year - 10; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    );
  }
  return { month, year, monthOptions, options };
};

export const stringTime = (time) => {
  if (typeof time !== 'string') {
    return '-';
  }
  return moment(time, [timeFormat]).format(timeFormat12);
};

export const stringDateTime = (dateTime) => {
  if (typeof dateTime !== 'string') {
    return '-';
  }

  const fecha = new Date(dateTime).toLocaleDateString('es-MX', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'America/Tijuana',
  });

  const hora = new Date(dateTime).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/Tijuana',
  });

  return `${fecha}, ${hora}`;
};

export const stringTime24 = (time) => {
  if (typeof time !== 'string') {
    return '-';
  }
  return moment(time, [timeFormat]).format(timeFormat24);
};

export const reportCurrentDateTime = () => {
  return moment().format('DD/MM/YYYY HH:MM');
};

export const reportDate = (date) => {
  if (!date) return '-';
  return moment(date).format('DD/MM/YYYY');
};

export const stringTimeToNumber = (stringDate) => {
  let formattedTime = parseFloat(stringDate) || 0;

  if (stringDate?.includes(':30:00')) formattedTime += 0.5;
  return formattedTime;
};
