import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useNavigate } from 'react-router-dom';
import {
  clearCancellationsModal,
  deleteItemModal,
  modalButtons,
  tableMenuOpt,
} from '../../../../constants';

export const CustomerTableOptions = ({
  id = null,
  path = null,
  action = {},
  privileges = [],
  labels = null,
}) => {
  const navigate = useNavigate();
  const { onDelete, onEdit, onAddNote, onClearCancellations } =
    !!action && action;
  let options = tableMenuOpt(labels).filter((opt) => {
    if (onClearCancellations.cancellations === 0) {
      return privileges.includes(opt.privilege) && opt.key !== 'desbanear';
    }

    return privileges.includes(opt.privilege);
  });

  const renderMenu = (id) => {
    return (
      <Menu onClick={({ key }) => handleMenuClick(key, id)} items={options} />
    );
  };

  const handleMenuClick = (key, id) => {
    switch (key) {
      case 'edit':
        onEdit
          ? onEdit.getModal(...onEdit.params)
          : navigate(`/dashboard/${path}/${id}`);
        break;
      case 'delete':
        showDeleteConfirm();
        break;
      case 'note':
        onAddNote.getModal(...onAddNote.params);
        break;
      case 'desbanear':
        showClearConfirm();
        break;
      default:
        break;
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: deleteItemModal.title,
      icon: <ExclamationCircleOutlined />,
      content: deleteItemModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise((resolve) => {
          setTimeout(resolve(onDelete.action(...onDelete.params)), 1000);
        });
      },
      onCancel() {},
    });
  };

  const showClearConfirm = () => {
    confirm({
      title: clearCancellationsModal.title,
      icon: <ExclamationCircleOutlined />,
      content: clearCancellationsModal.body,
      okType: 'danger',
      okText: modalButtons.confirm,
      cancelText: modalButtons.cancel,
      onOk() {
        return new Promise((resolve) => {
          setTimeout(
            resolve(
              onClearCancellations.action(...onClearCancellations.params)
            ),
            1000
          );
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Dropdown.Button
        disabled={!(options.length > 0)}
        overlay={renderMenu(id)}
        trigger={['click']}
        placement="bottom"
      />
    </>
  );
};
